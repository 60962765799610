import Image from 'next/image';

import {
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { mediaStoreBaseURL, USER_EVENT_STAGE } from '@/config/common';
import { parseNumberedLabel } from '@/lib/utils';
import { getLabelForEventRequestIsEndToEnd } from '@/services/userEventCartList.service';

const EventCartListSideBarHead = ({
  displayHostEventProfile,
  isMobile,
  userEvent
}) => {
  const {
    stage,
    userEventDetails: [{ checkoutEvent = {} } = {}] = [],
    userEventNumber,
    zendeskTicketDetail
  } = userEvent;
  const { eventRequestIsEndToEnd } = zendeskTicketDetail || {};
  return (
    <div className='flex flex-row md:flex-col gap-4'>
      <div className='flex items-center gap-4'>
        <div className={`${isMobile ? 'w-14' : 'w-24'} rounded-lg`}>
          <Image
            alt='Event'
            className='rounded-lg'
            height={0}
            id='user-event-icon'
            priority={true}
            src={`${mediaStoreBaseURL}/${checkoutEvent.iconUrl}`}
            style={{
              height: isMobile ? 60 : 100,
              width: isMobile ? 60 : 100
            }}
            unoptimized={true}
            width={0}
          />
        </div>
        <TextKeyValuePair
          {...{
            className: 'flex-col !gap-2',
            label: checkoutEvent?.event.name,
            labelClass: 'text-brand text-xl w-72 truncate-text font-semibold',
            spaceTop: 'mt-0',
            value: getLabelForEventRequestIsEndToEnd(eventRequestIsEndToEnd),
            valueClassName: 'text-base font-medium text-nero'
          }}
        />
      </div>
      <div className='flex flex-col'>
        <div className='flex justify-between mb-2 mt-1'>
          <TextWithIcon
            {...{
              className:
                'flex border border-brand rounded-lg px-2 py-1 items-center gap-4 md:gap-2 cursor-pointer',
              icon: 'view.svg',
              onClick: displayHostEventProfile,
              label: 'Event Profile',
              labelStyle:
                'text-brand text-sm font-medium cursor-pointer self-center leading-4'
            }}
          />
          <div className='flex gap-2'>
            {stage && (
              <Text
                {...{
                  className: `${USER_EVENT_STAGE[stage].class} rounded-lg px-2 py-1 text-sm font-medium self-center items-center flex`,
                  content: USER_EVENT_STAGE[stage].label
                }}
              />
            )}
            <TextWithIcon
              {...{
                className:
                  'bg-white border-neutral border rounded-lg px-3 py-1 self-center items-center flex',
                icon: 'event-red-icon.svg',
                label: parseNumberedLabel(userEventNumber),
                labelStyle: 'text-sm font-medium text-dim-gray'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCartListSideBarHead;
