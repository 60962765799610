import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useMenu } from 'react-instantsearch';
import { createInstantSearchRouterNext } from 'react-instantsearch-router-nextjs';

import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import { getMenuFromCurrentURL } from '@/helpers/searchkit';

const tabStyle = `navigate-cart border-t border-r border-l self-center items-center border-neutral bg-white text-sm px-4 py-[10px] cursor-pointer font-medium flex rounded-t-lg justify-center`;
const tabLabelStyle = 'searchkit-list-label text-nowrap';
const tabCountStyle = `ais-Menu-count searchkit-menu-list-count text-xs font-semibold rounded-full flex justify-center items-center align-middle bg-light-red text-dim-gray border-none min-w-6`;

const handleTabMenuClick = ({
  defaultTabCategory,
  key,
  onTabMenuSwitch,
  refine,
  setSelectedTabMenu
}) => {
  setSelectedTabMenu((prevSelectedValue) => {
    const unSelectActiveTabCategory = prevSelectedValue === key;
    return unSelectActiveTabCategory ? defaultTabCategory : key;
  });
  refine(key);
  onTabMenuSwitch();
};

const getEnrichedTabCategoryConfigList = ({
  items,
  selectedTabMenu,
  tabCategoryConfigList
}) =>
  tabCategoryConfigList
    .map(({ activeIcon, icon, label, key: expectedBucketValue }) => {
      const tabCategoryAttributesToPreserve = { activeIcon, icon, label };
      const defaultEnrichedTabCategoryConfig = {
        ...tabCategoryAttributesToPreserve,
        count: 0,
        data: null,
        exhaustive: true,
        isRefined: selectedTabMenu === expectedBucketValue,
        key: expectedBucketValue
      };

      const itemOfTabCategory = items.find(
        ({ value: bucketValue }) => expectedBucketValue === bucketValue
      );

      const itemOfTabCategoryExists = !(
        _.isNull(itemOfTabCategory) || _.isEmpty(itemOfTabCategory)
      );

      if (itemOfTabCategoryExists) {
        return {
          ...itemOfTabCategory,
          ...tabCategoryAttributesToPreserve,
          key: itemOfTabCategory.value
        };
      }

      return defaultEnrichedTabCategoryConfig;
    })
    .filter(Boolean);

const ExclusiveTabsMenu = ({
  defaultTabCategory,
  enrichedTabCategoryConfigList,
  onTabMenuSwitch,
  refine,
  setSelectedTabMenu,
  showTabMenuCount
}) =>
  enrichedTabCategoryConfigList.map(
    ({ activeIcon, count, icon, isRefined, label, key }, index) => (
      <li
        className={`${tabStyle} ${isRefined ? 'selected-menu-tab bg-brand-gradient text-white' : 'text-dim-gray bg-red-500'}`}
        key={`${key}_${index}`}
        onClick={() =>
          !isRefined &&
          handleTabMenuClick({
            defaultTabCategory,
            key,
            onTabMenuSwitch,
            refine,
            setSelectedTabMenu
          })
        }
      >
        <div className='flex justify-center items-center'>
          <TextWithIcon
            {...{
              className: `tab-menu-label shadow-none ${isRefined ? 'active-tab-menu-label' : '!gap-2'}`,
              icon: `${isRefined ? activeIcon : icon}.svg`,
              iconHeight: 28,
              iconWidth: 28,
              label,
              labelStyle: tabLabelStyle
            }}
          />

          {showTabMenuCount && (
            <Text
              {...{
                className: `tab-menu-count ${tabCountStyle}`,
                content: count,
                HtmlTag: 'span'
              }}
            />
          )}
        </div>
      </li>
    )
  );

const AllTabMenu = ({
  allTabMenuConfig,
  allTabMenuCount,
  isExclusiveTabSelected,
  onAllTabMenuClick,
  selectedTabMenu,
  showTabMenuCount
}) => {
  const { activeIcon, icon, key, label } = allTabMenuConfig;
  const isAllTabMenuSelected =
    _.isNull(selectedTabMenu) || selectedTabMenu === key;

  return (
    <li
      className={`${tabStyle} ${
        isExclusiveTabSelected
          ? 'text-dim-gray'
          : 'selected-menu-tab bg-brand-gradient text-white'
      } `}
      key={key}
      onClick={onAllTabMenuClick}
    >
      <div
        {...{
          className: `${isAllTabMenuSelected ? 'gap-2' : 'gap-0'} flex justify-center items-center`
        }}
      >
        <TextWithIcon
          {...{
            className: `tab-menu-label shadow-none ${isAllTabMenuSelected ? 'active-tab-menu-label' : '!gap-2'}`,
            icon: `${isAllTabMenuSelected ? icon : activeIcon}.svg`,
            iconHeight: 28,
            iconWidth: 28,
            label,
            labelStyle: tabLabelStyle
          }}
        />
        {showTabMenuCount && (
          <Text
            {...{
              className: `tab-menu-count ${isAllTabMenuSelected}`,
              content: allTabMenuCount,
              HtmlTag: 'span'
            }}
          />
        )}
      </div>
    </li>
  );
};

const TabMenu = ({
  allTabMenuConfig,
  attribute,
  defaultTabCategory,
  index,
  onTabMenuSwitch = () => {},
  selectedTabMenu,
  setSelectedTabMenu,
  showTabMenuCount = false,
  tabCategoryConfigList
}) => {
  const { items, refine } = useMenu({ attribute });
  const router = useRouter();
  const instantSearchRouter = createInstantSearchRouterNext({
    routerOptions: {
      cleanUrlOnDispose: false
    }
  });

  const handlePopState = () => {
    getMenuFromCurrentURL({
      attribute,
      index,
      instantSearchRouter,
      setSelectedTab: setSelectedTabMenu
    });
  };

  useEffect(() => {
    handlePopState();
    router.beforePopState(() => {
      handlePopState();
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, [router]);

  const enrichedTabCategoryConfigList = getEnrichedTabCategoryConfigList({
    items,
    selectedTabMenu,
    tabCategoryConfigList
  });
  const isExclusiveTabSelected = enrichedTabCategoryConfigList.some(
    ({ isRefined }) => isRefined
  );

  const allTabMenuCount = items.reduce(
    (accumulator, { count }) => accumulator + count,
    0
  );

  const onAllTabMenuClick = () => {
    if (isExclusiveTabSelected) {
      refine('');
      setSelectedTabMenu(allTabMenuConfig.key);
      onTabMenuSwitch();
    }
  };

  return (
    <div className='-mt-8 mx-6 md:mt-0 md:mx-0 searchkit-list-menu relative -left-[1.5rem]'>
      <ul className='flex gap-3 items-center tab-menu'>
        <ExclusiveTabsMenu
          {...{
            defaultTabCategory,
            enrichedTabCategoryConfigList,
            onTabMenuSwitch,
            refine,
            setSelectedTabMenu,
            showTabMenuCount
          }}
        />
        <AllTabMenu
          {...{
            allTabMenuConfig,
            allTabMenuCount,
            isExclusiveTabSelected,
            onAllTabMenuClick,
            selectedTabMenu,
            showTabMenuCount
          }}
        />
      </ul>
    </div>
  );
};

export default TabMenu;
