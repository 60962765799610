import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import {
  Button,
  CloseIcon,
  InputField,
  SelectFormInput,
  Text
} from '@/components/atomic/nuclei';
import { HOST_CREDIT_ENTITY_NAME_VALIDATION_CONFIG } from '@/config/common';
import useYupValidator from '@/lib/yupValidator';
import {
  createHostCreditEntity,
  fetchOpsUserList
} from '@/services/hostCreditEntity.service';
import { getPlannerId } from '@/services/identity.service';

const { nameInputError, nameRegex } = HOST_CREDIT_ENTITY_NAME_VALIDATION_CONFIG;

const AddNewCreditEntityModal = ({
  creditEntityModalToggle,
  setSelectedHostCreditEntityName,
  setShowToastContainer,
  setValue: setValueOnParent
}) => {
  const [opsUserList, setOpsUserList] = useState([]);

  useEffect(() => {
    fetchOpsUserList({
      setOpsUserList,
      setShowToast: setShowToastContainer
    });
  }, []);

  const yupResolver = useYupValidator(
    yup.object().shape({
      name: yup
        .string()
        .trim()
        .matches(nameRegex, nameInputError)
        .required('Entity Name is required.')
    })
  );

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    register,
    setError,
    setValue
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver,
    values: {
      accountManagerId: getPlannerId()
    }
  });

  const { isValid, errors } = useFormState({
    control
  });

  const onSubmit = (dataToSubmit) =>
    createHostCreditEntity({
      dataToSubmit,
      setError,
      setSelectedHostCreditEntityName,
      setShowToastContainer,
      setValueOnParent
    });

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={true}
    >
      <ModalBody>
        <div className='flex h-screen justify-center'>
          <div className='self-center relative bg-white px-6 rounded-lg w-[480px]'>
            <div className='flex justify-end pt-4 mb-4 relative left-3 -top-1'>
              <CloseIcon
                {...{ onClick: () => creditEntityModalToggle(false) }}
              />
            </div>
            <Text
              {...{
                className: 'text-xl font-medium text-nero mb-3 text-center',
                content: 'Add New Credit Entity'
              }}
            />
            <div className='flex flex-col gap-4 mt-4'>
              <InputField
                {...{
                  className: 'rounded-lg pl-10',
                  dbName: 'name',
                  errors,
                  iconURL: 'host-credit-entity-red-icon.svg',
                  inputGroup: 'flex flex-col gap-2',
                  label: 'Entity Name *',
                  labelClass: 'text-nero text-base font-medium',
                  maxLength: 50,
                  minLength: 3,
                  placeholder: 'Enter Entity Name',
                  register: {
                    ...register('name')
                  }
                }}
              />
              <SelectFormInput
                {...{
                  clearErrors,
                  containerClassName:
                    'relative multi-select-epm epm-dropdown border border-platinum rounded',
                  dbName: 'accountManagerId',
                  errors,
                  getValues,
                  icon: {
                    url: 'planner-icon-orange.svg'
                  },
                  isClearable: false,
                  label: 'Account Manager *',
                  name: 'Account Manager Id',
                  placeholderLabel: 'Account Manager',
                  register,
                  setValue,
                  showIcon: true,
                  showLabel: true,
                  values: opsUserList
                }}
              />
            </div>
            <Button
              {...{
                className:
                  'bg-brand-gradient text-white text-sm font-medium py-3 rounded mt-4 mb-4',
                disabled: !isValid,
                label: 'Add',
                onClick: handleSubmit(onSubmit)
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddNewCreditEntityModal;
