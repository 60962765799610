import _ from 'lodash';

import {
  ALLOCATION_STATUS,
  CART_STATUS,
  EVENT_REQUEST_TYPE,
  HOST_TYPES_WITH_ADDITIONAL_REQUIREMENTS,
  MANDATORY_FIELDS_FOR_EPIC_ENHANCED,
  MANDATORY_FIELDS_FOR_EPIC_GENERATED,
  USER_EVENT_STAGE,
  VERIFICATION_STATUSES,
  ZENDESK_PRIORITY
} from '@/config/common';
import { handleOnClickZendesk } from '@/helpers/zendeskTicket';
import copyToClipBoard from '@/lib/copyToClipBoard';
import { getSerializedRichText } from '@/lib/editor';
import { convertToShortMonthDateFormat, formatTimeToAmPm } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import * as HttpService from '@/services/http.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';
import { createTenderAndPublishBids } from '@/services/tender.service';
import {
  ADD_NEW_CART_API_URL,
  CLONE_CART_TO_USER_EVENT_API_URL,
  CLONE_NON_ORDERED_CART_API_URL,
  CLONE_ORDERED_CART_API_URL,
  GET_ORDER_MEDIA_BY_EVENT_ID_API_URL,
  GET_USER_EVENT_CART_DETAILS_API_URL,
  UPDATE_ARCHIVED_CART_STATUS_API_URL,
  UPDATE_CART_INFO_API_URL,
  VERIFY_CART_NUMBER_EXISTENCE_API_API_URL,
  VERIFY_EVENT_NUMBER_EXISTENCE_API_URL
} from '@/services/url.service';

const DUMMY_EVENT_DETAILS = {
  checkoutEventId: null,
  contactMethodId: null,
  eventContactEmail: 'haflacustomer',
  eventContactName: 'ZD_AUTO_CUST',
  eventDate: '31st Dec 2099',
  eventDurationInDays: null,
  eventSiteTypeId: null,
  eventStartTime: '24:00',
  eventTitle: null,
  eventVerticals: [],
  expectedGuestCount: null,
  formattedAddress: 'Nook Coworking - Dubai - United Arab Emirates',
  hostCreditEntityId: null,
  opportunityValue: null,
  requesterType: null,
  theme: null,
  triageSummary: '',
  zendeskTicketId: null
};

const getUserEventCartDetailsAPI = ({ userEventId }) =>
  HttpService.getWithAuth(GET_USER_EVENT_CART_DETAILS_API_URL({ userEventId }));

const getOrderMediaByEventIdAPI = ({ userEventId }) =>
  HttpService.getWithAuth(GET_ORDER_MEDIA_BY_EVENT_ID_API_URL({ userEventId }));

const updateCartInfoAPI = ({ userCartId, cartId, data }) =>
  HttpService.patchWithAuth(
    UPDATE_CART_INFO_API_URL({ userCartId, cartId }),
    data
  );

const cloneNonOrderedCartAPI = ({ userCartId, cartId, data }) =>
  HttpService.postWithAuth(
    CLONE_NON_ORDERED_CART_API_URL({ userCartId, cartId }),
    data
  );

const cloneOrderedCartAPI = ({ userCartId, cartId, data }) =>
  HttpService.postWithAuth(
    CLONE_ORDERED_CART_API_URL({ userCartId, cartId }),
    data
  );

const updateArchivedStatusAPI = ({ userCartId, cartId, isArchived }) =>
  HttpService.patchWithAuth(
    UPDATE_ARCHIVED_CART_STATUS_API_URL({ userCartId, cartId }),
    { isArchived }
  );

const addNewCartAPI = (userId, data) =>
  HttpService.postWithAuth(ADD_NEW_CART_API_URL({ userId }), data);

const verifyCartNumberExistenceAPI = ({ cartNumber }) =>
  HttpService.getWithAuth(
    VERIFY_CART_NUMBER_EXISTENCE_API_API_URL({ cartNumber })
  );

const cloneCartToUserEventAPI = ({ cartNumber, userEventNumber }) =>
  HttpService.postWithAuth(CLONE_CART_TO_USER_EVENT_API_URL(), {
    cartNumber,
    userEventNumber
  });

const verifyEventNumberExistenceAPI = ({ userEventNumber }) =>
  HttpService.getWithAuth(
    VERIFY_EVENT_NUMBER_EXISTENCE_API_URL({ userEventNumber })
  );

export const checkIsEmptyOrDummyEventDetail = ({ key, value }) => {
  const dummyValue = _.get(DUMMY_EVENT_DETAILS, key, 'key-not-found');
  if (_.isNil(value)) {
    return true;
  }

  if (dummyValue === 'key-not-found') {
    return false;
  }

  if (_.isNumber(value)) {
    return dummyValue === value;
  }

  if (_.isString(value) && _.isString(dummyValue)) {
    if (value?.trim() === '') {
      return true;
    }

    if (dummyValue?.trim() !== '') {
      return value.includes(dummyValue);
    }

    return false;
  }

  if (!_.isBoolean(value) && _.isEmpty(value)) {
    return true;
  }

  return false;
};

export const fetchOrderMediaList = async ({ userEventId }) => {
  const { entity, status, message } = await getOrderMediaByEventIdAPI({
    userEventId
  });
  if (status) {
    if (!_.isEmpty(entity?.orders)) {
      const userEventMediaList = entity.orders
        .map(({ orderMedia }) => orderMedia)
        .flat();
      return userEventMediaList;
    }
    return [];
  }
  return Promise.reject(new Error(message));
};

const fetchStaticAPIs = async ({
  setStaticEventCartDetails,
  setShowToast,
  userEventId
}) => {
  try {
    const [userEventMediaList] = await Promise.all([
      fetchOrderMediaList({ userEventId })
    ]);
    setStaticEventCartDetails({
      userEventMediaList
    });
  } catch (error) {
    setShowToast({
      message: error.message || 'Error while fetching static API Info',
      show: true,
      successToast: false
    });
  }
};

export const fetchEventCartDetailsOnPageLoad = async ({
  setLoading,
  setShowToast,
  setStaticEventCartDetails,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const { status, entity, message } = await getUserEventCartDetailsAPI({
    userEventId
  });
  if (status && entity.userEventInformation) {
    setUserEventCartDetails(entity);
  }
  await fetchStaticAPIs({
    setStaticEventCartDetails,
    setShowToast,
    userEventId
  });
  setLoading(false);
  (!status || !entity.userEventInformation) &&
    setShowToast({ message, show: true, successToast: status });
};

export const fetchEventCartDetails = async ({
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const { status, entity, message } = await getUserEventCartDetailsAPI({
    userEventId
  });
  if (status && entity.userEventInformation) {
    setUserEventCartDetails(entity);
  }
  setLoading(false);
  (!status || !entity.userEventInformation) &&
    setShowToast({ message, show: true, successToast: status });
};

export const fetchAndSetCartDetails = async ({
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const {
    entity: { userEventCartList },
    message,
    status
  } = await getUserEventCartDetailsAPI({
    userEventId
  });
  if (status) {
    setUserEventCartDetails((prevState) => ({
      ...prevState,
      userEventCartList
    }));
  }
  setLoading(false);
  !status && setShowToast({ message, show: true, successToast: status });
};

export const invokeArchiveCart = async ({
  cartId,
  isArchived,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId,
  userId
}) => {
  setLoading(true);
  try {
    const { status, message } = await updateArchivedStatusAPI({
      userCartId: userId,
      cartId,
      isArchived
    });
    status &&
      fetchAndSetCartDetails({
        setLoading,
        setShowToast,
        setUserEventCartDetails,
        userEventId
      });
    !status && setShowToast({ message, show: true, successToast: status });
  } catch (err) {
    setShowToast({
      message: err.response?.data?.message || 'API Failed',
      show: true,
      successToast: false
    });
  }
  setLoading(false);
};

export const cloneCartAPI = async ({
  cartId,
  cartStatus,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId,
  userId
}) => {
  setLoading(true);
  const cloneCart =
    cartStatus === CART_STATUS.ORDER.value
      ? cloneOrderedCartAPI
      : cloneNonOrderedCartAPI;

  const { status, message } = await cloneCart({
    cartId,
    userCartId: userId
  });

  status &&
    fetchAndSetCartDetails({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      userEventId
    });
  setShowToast({ message, show: true, successToast: status });
  setLoading(false);
};

export const blockScreenCreateTenderAndDispatchBids = async ({
  data,
  firstTender = false,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId
}) => {
  setLoading(true);
  const { status, message } = await createTenderAndPublishBids({
    data,
    firstTender
  });
  if (status) {
    await fetchAndSetCartDetails({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      userEventId
    });
  }
  setShowToast({
    message: message ?? message[0],
    show: true,
    successToast: status
  });
  setLoading(false);
};

export const createNewCart = async ({
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventCartDetails
}) => {
  const {
    userEventDetails: [{ eventContactName = '', userEventId } = {}] = [],
    userId
  } = userEventCartDetails.userEventInformation;
  const cartNamePrefix = eventContactName ? `${eventContactName}'s ` : '';
  const cartName = `${cartNamePrefix}Cart-${Date.now()}`;

  setLoading(true);
  const { status, message } = await addNewCartAPI(userId, {
    userEventId,
    cartName
  });
  if (status) {
    await fetchAndSetCartDetails({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      userEventId
    });
  }
  setShowToast({ message, show: true, successToast: status });
  setLoading(false);
};

export const updateCart = async ({
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  updateCartDetail,
  userId,
  userEventId
}) => {
  setLoading(true);
  try {
    const { status, message } = await updateCartInfoAPI({
      userCartId: userId,
      cartId: updateCartDetail.data.id,
      data: updateCartDetail.data
    });

    if (status) {
      await fetchAndSetCartDetails({
        setLoading,
        setShowToast,
        setUserEventCartDetails,
        userEventId
      });
    }
    setShowToast({ message, show: true, successToast: status });
  } catch (error) {
    setShowToast({ message: error.message, show: true, successToast: false });
  }
  setLoading(false);
};

const countIncompleteEPICGeneratedFields = ({
  user,
  userEventDetails: [eventDetail],
  zendeskTicketId
}) => {
  const {
    checkoutEventId,
    eventContactName,
    eventDate,
    eventVerticals,
    expectedGuestCount,
    triageSummary
  } = eventDetail;
  const { hostSegment } = user || {};
  const requesterType = hostSegment?.name || null;

  const requiredEventDetailKeyValueList = [
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EVENT_DATE,
      value: eventDate ? convertToShortMonthDateFormat(eventDate) : null
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EVENT_TYPE,
      value: checkoutEventId
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EVENT_VERTICALS,
      value: eventVerticals
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.EXPECTED_GUEST_COUNT,
      value: expectedGuestCount
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.REQUESTER_TYPE,
      value: requesterType
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.REQUESTER_NAME,
      value: eventContactName
    },

    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.TRIAGE_SUMMARY,
      value: getSerializedRichText(triageSummary)
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_GENERATED.ZENDESK_TICKET_ID,
      value: zendeskTicketId
    }
  ];
  return requiredEventDetailKeyValueList.filter(checkIsEmptyOrDummyEventDetail)
    .length;
};

export const validateMandatoryFieldsForEPICEnhanced = ({
  contactMethodId,
  eventDurationInDays,
  user,
  userEventDetails: [eventDetail],
  zendeskTicketId
}) => {
  const { hostCreditEntityId, hostSegment } = user || {};
  const requesterType = hostSegment?.name || null;

  const {
    eventAddress,
    eventContactEmail,
    eventSiteTypeId,
    eventStartTime,
    eventTitle,
    opportunityValue,
    theme
  } = eventDetail;

  const formattedAddress =
    typeof eventAddress === 'string'
      ? eventAddress
      : eventAddress?.formattedAddress;

  const requiredFieldsToGoInEPICEnhancedStage = [
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.CONTACT_METHOD_ID,
      value: contactMethodId
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_DURATION_IN_DAYS,
      value: eventDurationInDays
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_SITE_TYPE_ID,
      value: eventSiteTypeId
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_START_TIME,
      value: eventStartTime
    },
    { key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.EVENT_TITLE, value: eventTitle },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.FORMATTED_ADDRESS,
      value: formattedAddress
    },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.OPPORTUNITY_VALUE,
      value: opportunityValue
    },
    { key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.THEME, value: theme },
    {
      key: MANDATORY_FIELDS_FOR_EPIC_ENHANCED.ZENDESK_TICKET_ID,
      value: zendeskTicketId
    }
  ];

  const enableAdditionalRequirements =
    HOST_TYPES_WITH_ADDITIONAL_REQUIREMENTS.includes(requesterType);

  const additionalRequiredFieldsBasedOnHostType = [
    { key: 'eventContactEmail', value: eventContactEmail },
    { key: 'hostCreditEntityId', value: hostCreditEntityId }
  ];

  const requiredFieldsToGoInEPICEnhancedStageBasedOnHostType =
    enableAdditionalRequirements ? additionalRequiredFieldsBasedOnHostType : [];

  const allRequiredFieldsToGoInEPICEnhancedStage = [
    ...requiredFieldsToGoInEPICEnhancedStage,
    ...requiredFieldsToGoInEPICEnhancedStageBasedOnHostType
  ];

  const anyMandatoryFieldWithDummyValue =
    allRequiredFieldsToGoInEPICEnhancedStage.some(
      checkIsEmptyOrDummyEventDetail
    );

  return !anyMandatoryFieldWithDummyValue;
};

export const getEPICGenrationCompletionPercentage = (userEvent) => {
  const countOfMandatoryFieldsForEPICGenerated = Object.keys(
    MANDATORY_FIELDS_FOR_EPIC_GENERATED
  ).length;

  const incompleteFieldsCount = countIncompleteEPICGeneratedFields(userEvent);

  return parseFloat(
    ((countOfMandatoryFieldsForEPICGenerated - incompleteFieldsCount) * 100) /
      countOfMandatoryFieldsForEPICGenerated
  ).toFixed(2);
};

export const validateMandatoryFieldsForEPICGenerated = (userEvent) =>
  countIncompleteEPICGeneratedFields(userEvent) === 0;

export const verifyCartNumberExistence = async ({
  inputValue: cartNumber,
  setInvalidMessage: setInvalidCartNumberMessage,
  setTargetEntity: setVerifiedCartDetail,
  setVerificationStatus: setCartVerificationStatus
}) => {
  const { FAILED, VERIFIED } = VERIFICATION_STATUSES;
  const { status, entity } = await verifyCartNumberExistenceAPI({
    cartNumber
  });
  if (status && entity.cartExists) {
    setVerifiedCartDetail({ ...entity.cart, cartNumber });
    setCartVerificationStatus(VERIFIED);
  } else {
    const { error: { errorMessage } = {} } = entity;
    const apiErrorMessage = errorMessage
      ? 'Cart status Invalid'
      : 'Cart number Invalid.';
    setCartVerificationStatus(FAILED);
    setInvalidCartNumberMessage(apiErrorMessage);
  }
};

export const cloneCartToUserEvent = async ({
  cartNumber,
  closeActionModal,
  setInvalidMessage,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  shouldFetchEventCartDetails = false,
  userEventId,
  userEventNumber
}) => {
  const { status, message } = await cloneCartToUserEventAPI({
    cartNumber,
    userEventNumber
  });
  if (status) {
    if (shouldFetchEventCartDetails) {
      await fetchEventCartDetails({
        setLoading,
        setShowToast,
        setUserEventCartDetails,
        userEventId
      });
    }
    closeActionModal();
    setShowToast({ message, show: true, successToast: status });
  } else {
    setInvalidMessage(message);
  }
};

export const verifyEventNumberExistence = async ({
  inputValue: userEventNumber,
  setInvalidMessage: setInvalidEventNumberMessage,
  setTargetEntity: setVerifiedEventDetail,
  setVerificationStatus: setEventVerificationStatus
}) => {
  const { FAILED, VERIFIED } = VERIFICATION_STATUSES;
  const { status, entity } = await verifyEventNumberExistenceAPI({
    userEventNumber
  });

  if (status && entity.userEventExists) {
    const {
      userEvent: {
        userEventDetails: [
          { eventTitle = '', checkoutEvent: { event: { name } = {} } = {} } = {}
        ] = []
      }
    } = entity;
    const eventName = eventTitle || name;
    setVerifiedEventDetail({ ...entity.userEvent, eventName, userEventNumber });
    setEventVerificationStatus(VERIFIED);
  } else {
    setEventVerificationStatus(FAILED);
    setInvalidEventNumberMessage('Event number Invalid.');
  }
};

export const getLabelForEventRequestIsEndToEnd = (eventRequestIsEndToEnd) =>
  eventRequestIsEndToEnd
    ? EVENT_REQUEST_TYPE.END_TO_END
    : EVENT_REQUEST_TYPE.PRODUCT_AND_SERVICE_ONLY;

export const onSubmitCartChanges = async ({
  allocationStatus,
  setCartStatusChangeValidationError,
  status: targetCartStatus,
  updateCartDetailOnSave,
  userEventInformation,
  ...rest
}) => {
  const allowCartChanges = () => {
    if (allocationStatus === ALLOCATION_STATUS.EVENT_ACCEPTED.value) {
      return (
        validateMandatoryFieldsForEPICGenerated(userEventInformation) &&
        validateMandatoryFieldsForEPICEnhanced(userEventInformation)
      );
    }
    return false;
  };

  if (targetCartStatus === CART_STATUS.COLLABORATE.value) {
    if (!allowCartChanges()) {
      setCartStatusChangeValidationError(true);
      return null;
    }
  }

  return updateCartDetailOnSave({ status: targetCartStatus, ...rest });
};

export const createCartWarningRequiredByEventStage = ({ stage }) =>
  [
    USER_EVENT_STAGE.EPIC_GENERATED.key,
    USER_EVENT_STAGE.EVENT_ACCEPTED.key,
    USER_EVENT_STAGE.EVENT_ASSIGNED.key
  ].includes(stage);

export const getZendeskTicketPriorityConfig = (priority) => {
  const configForOldTicketWherePriorityIsMissing = {
    className: 'expired rounded-lg border border-error-red',
    icon: 'red-status-icon.svg',
    value: 'NA'
  };
  return (
    Object.values(ZENDESK_PRIORITY).find(({ value }) => value === priority) ??
    configForOldTicketWherePriorityIsMissing
  );
};

export const parseUserEventInfoForEPIC = ({ key, placeholderValue, value }) =>
  checkIsEmptyOrDummyEventDetail({ key, value })
    ? placeholderValue
    : `${value}`;

export const getEPICExplanationStringInput = ({
  epicParameters: {
    city,
    eventDate,
    eventType,
    guests,
    hostName,
    hostType,
    verticals
  }
}) => [
  {
    prefix: '',
    rank: 1, // TODO: Sidd to revisit, once feature is stable remove key
    value: parseUserEventInfoForEPIC({
      key: 'eventContactName',
      placeholderValue: '{Host Name}',
      value: hostName
    })
  },
  {
    key: 'requesterType',
    prefix: ', a(n) ',
    rank: 2,
    value: parseUserEventInfoForEPIC({
      key: 'requesterType',
      placeholderValue: '{Host Type}',
      value: hostType
    })
  },
  {
    prefix: ', is planning a ',
    rank: 3,
    value: parseUserEventInfoForEPIC({
      key: 'eventType',
      placeholderValue: '{Event Type}',
      value: eventType
    })
  },
  {
    prefix: ' on ',
    rank: 4,
    value: parseUserEventInfoForEPIC({
      key: 'eventDate',
      placeholderValue: '{Event Date}',
      value: eventDate ? convertToShortMonthDateFormat(eventDate) : null
    })
  },
  {
    prefix: ' for ',
    rank: 5,
    value: parseUserEventInfoForEPIC({
      key: 'expectedGuestCount',
      placeholderValue: '{No. of guests}',
      value: guests
    })
  },
  {
    prefix: ' guests in ',
    rank: 6,
    value: parseUserEventInfoForEPIC({
      key: 'formattedAddress',
      placeholderValue: '{City}',
      value: city
    })
  },
  {
    rank: 7,
    prefix: ' and is looking for ',
    value: parseUserEventInfoForEPIC({
      key: 'eventVerticals',
      placeholderValue: '{Hafla Request Vertical(s)}',
      value:
        verticals.length > 0
          ? verticals.map(({ name }) => name).join(', ')
          : verticals
    })
  }
];

export const copyToClipboardEPICGeneation = async ({
  epicParameters,
  setShowToast
}) => {
  const isClipboardAvailable = Boolean(navigator.clipboard);
  if (isClipboardAvailable) {
    const epicGenerationMessage = getEPICExplanationStringInput({
      epicParameters
    })
      .map(({ prefix, value }) => `${prefix}${value}`)
      .join('');

    await copyToClipBoard(epicGenerationMessage);
  }
  setShowToast({
    message: isClipboardAvailable
      ? 'Successfully copied to clipboard'
      : 'Failed to copy to clipboard',
    show: true,
    successToast: isClipboardAvailable
  });
};

export const onClickAddToCart = ({
  setLoading,
  setShowErrorModal,
  setShowToast,
  setUserEventCartDetails,
  userEvent,
  userEventCartDetails
}) => {
  const issueWarningByEventStage = createCartWarningRequiredByEventStage({
    stage: userEvent.stage
  });
  if (issueWarningByEventStage) {
    setShowErrorModal(true);
  } else {
    createNewCart({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      userEventCartDetails
    });
  }
};

export const getSidebarBodyData = ({
  eventAddress,
  eventContactName,
  eventDate,
  eventStartTime,
  eventVerticals,
  expectedGuestCount,
  router,
  userId,
  zendeskTicketId
}) => [
  {
    className: 'justify-start gap-4 my-1',
    icon: 'zendesk-with-frame.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: parseNumberedLabel(zendeskTicketId),
    onClick: () => {
      zendeskTicketId && handleOnClickZendesk(zendeskTicketId);
    },
    labelStyle: `font-medium cursor-pointer self-center text-dim-gray ${
      zendeskTicketId ? '' : 'text-error-base'
    }`,
    iconSuffix: {
      alt: 'hand',
      icon: 'open-icon.svg',
      iconHeight: 24,
      iconStyle: 'ml-auto cursor-pointer',
      iconWidth: 24,
      id: 'zendeskIconTooltip'
    }
  },
  {
    className: 'justify-start gap-4 my-1',
    icon: 'user-red-with-frame.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: eventContactName || 'NA',
    labelStyle: `font-medium self-center text-dim-gray ${
      checkIsEmptyOrDummyEventDetail({
        key: 'eventContactName',
        value: eventContactName
      })
        ? 'text-error-base'
        : ''
    }`,
    iconSuffix: {
      alt: 'hand',
      icon: 'view-icon-with-frame.svg',
      iconHeight: 24,
      iconStyle: 'suffixIconStyle ml-auto cursor-pointer',
      iconWidth: 24,
      id: 'eventContactTooltip'
    },
    onClick: () => {
      router.push(
        getPageURL({
          excludeBaseUrl: false,
          pageName: PAGE_NAME.HOST_EVENT_LIST.label,
          pathParams: {
            userId
          }
        })
      );
    }
  },
  {
    className: 'justify-start gap-4 my-1',
    icon: 'calendar-red-with-frame.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: eventDate
      ? `${convertToShortMonthDateFormat(eventDate)}, ${formatTimeToAmPm(eventStartTime)}`
      : 'NA',
    labelStyle: `font-medium self-center text-dim-gray ${
      checkIsEmptyOrDummyEventDetail({
        key: 'eventDate',
        value: convertToShortMonthDateFormat(eventDate)
      })
        ? 'text-error-base'
        : ''
    }`
  },
  {
    className: 'justify-start gap-4 my-1',
    icon: 'location-icon-with-frame.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: eventAddress?.formattedAddress || 'NA',
    labelStyle: `font-medium self-center text-dim-gray ${
      checkIsEmptyOrDummyEventDetail({
        key: 'formattedAddress',
        value: eventAddress?.formattedAddress
      })
        ? 'text-error-base'
        : ''
    }`
  },
  {
    className: 'justify-start gap-4 my-1',
    icon: 'verticals-icon-red.svg',
    iconHeight: 24,
    iconWidth: 24,
    label:
      eventVerticals.length > 0
        ? eventVerticals.map(({ name }) => name).join(', ')
        : 'NA',
    labelStyle: 'font-medium self-center text-dim-gray'
  },
  {
    className: 'justify-start gap-4 my-1',
    icon: 'guest-icon-red.svg',
    iconHeight: 24,
    iconWidth: 24,
    label: expectedGuestCount ?? 'NA',
    labelStyle: 'font-medium self-center text-dim-gray'
  }
];
