import Image from 'next/image';

import { Button, Text, TextWithIcon } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const HoverCard = ({ hoverCard: { textWithIconList, cta } }) => (
  <div className='border-t border-neutral w-full flex justify-between gap-4 py-1 list-card-footer'>
    <div className='flex gap-4'>
      {textWithIconList.map(({ alt, icon, label }, index) => (
        <TextWithIcon
          key={index}
          {...{
            alt,
            className: 'px-2 py-1',
            icon,
            iconHeight: 20,
            iconWidth: 20,
            label,
            labelStyle: 'text-sm text-nero font-light',
            show: Boolean(label)
          }}
        />
      ))}
    </div>
    {cta && (
      <Button
        {...{
          className:
            'rounded-lg py-[4px] text-sm font-medium border border-brand mr-2 text-brand',
          iconURL: `${staticMediaStoreBaseURL}/icons/modal-open-icon-red.svg`,
          iconWidth: 20,
          iconHeight: 20,
          iconPosition: 'Left',
          label: `${cta.label}`,
          onClick: cta.onClick,
          width: 'w-36'
        }}
      />
    )}
  </div>
);

const CardHead = ({
  header: {
    subTitle: {
      alt: subTitleAlt,
      icon: subTitleIcon,
      value: subTitleValue,
      isBorder
    } = {},
    titleSubscript,
    title
  }
}) => (
  <div className='flex flex-col gap-1'>
    <div className='flex items-center gap-3'>
      <Text
        {...{
          className: 'text-nero text-xl font-medium max-w-60 truncate',
          content: title
        }}
      />
      {subTitleValue && (
        <>
          <div className='border-l-2 border-neutral h-4'></div>
          <TextWithIcon
            {...{
              alt: subTitleAlt,
              className: `${isBorder ? 'border border-neutral rounded' : ''} px-2 py-1`,
              icon: subTitleIcon,
              iconHeight: 20,
              iconWidth: 20,
              label: subTitleValue,
              labelStyle: 'text-sm text-nero font-light'
            }}
          />
        </>
      )}
    </div>
    {titleSubscript && (
      <Text
        {...{
          className: 'text-sm text-nero font-light',
          content: titleSubscript
        }}
      />
    )}
  </div>
);

const CardBody = ({
  body: {
    entityStatus: { statusClass, value } = {},
    entityNumber: {
      alt: entityNumberAlt,
      icon: entityNumberIcon,
      label: entityNumberLabel
    }
  }
}) => (
  <div className='flex gap-4 ml-auto'>
    {value && (
      <Text
        {...{
          className: `${statusClass} px-2 py-1 self-start rounded text-sm font-medium`,
          content: value
        }}
      />
    )}
    <TextWithIcon
      {...{
        alt: entityNumberAlt,
        className: 'border border-neutral rounded px-2 py-1',
        icon: entityNumberIcon,
        iconHeight: 20,
        iconWidth: 20,
        label: entityNumberLabel,
        labelStyle: 'text-sm text-dim-gray font-medium'
      }}
    />
  </div>
);

const MainCard = ({ body, header, icon }) => (
  <div className='flex flex-1 gap-4 p-3'>
    <Image
      alt={icon.alt}
      height={0}
      priority={true}
      src={`${staticMediaStoreBaseURL}/icons/${icon.name}`}
      style={{
        height: 50,
        width: 50
      }}
      width={0}
    />
    <CardHead {...{ header }} />
    <CardBody {...{ body }} />
  </div>
);

const ListCard = ({ listCard: { body, header, icon, onClick, hoverCard } }) => (
  <div
    className={
      'cursor-pointer border-neutral hover:border-brand border rounded-lg flex flex-col w-full hover:shadow-product-card list-card'
    }
    onClick={onClick}
  >
    <MainCard {...{ body, header, icon }} />
    {hoverCard && <HoverCard {...{ hoverCard }} />}
  </div>
);

export default ListCard;
