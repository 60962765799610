import {
  PLANNER_CREDIT_FACILITY_REVIEWERS,
  REVIEW_CREDIT_REQUEST_CTA_PAIR
} from '@/config/hostCreditEntity';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI
} from '@/lib/numberStringUtils';
import { isEmptyOrNull } from '@/lib/utils';
import * as HttpService from '@/services/http.service';
import { getPlannerId } from '@/services/identity.service';
import {
  ADD_HOST_CREDIT_ENTITY_API_URL,
  CREATE_HOST_CREDIT_ENTITY_CREDIT_REQUEST_API_URL,
  GET_CREDIT_REQUEST_REASONS_API_URL,
  GET_HOST_CREDIT_ENTITY_CREDIT_REQUESTS_API_URL,
  GET_HOST_CREDIT_ENTITY_HOST_LIST_API_URL,
  GET_HOST_CREDIT_ENTITY_PROFILE_DETAILS_API_URL,
  GET_OPS_USER_LIST_API_URL,
  UPDATE_HOST_CREDIT_ENTITY_API_URL,
  UPDATE_HOST_CREDIT_ENTITY_CREDIT_REQUEST_API_URL
} from '@/services/url.service';

const getHostCreditEntityHostListAPI = ({ hostCreditEntityId }) =>
  HttpService.getWithAuth(
    GET_HOST_CREDIT_ENTITY_HOST_LIST_API_URL({ hostCreditEntityId })
  );

const getHostCreditEntityProfileDetailsAPI = ({ hostCreditEntityId }) =>
  HttpService.getWithAuth(
    GET_HOST_CREDIT_ENTITY_PROFILE_DETAILS_API_URL({ hostCreditEntityId })
  );

const getHostCreditEntityCreditRequestsAPI = ({ hostCreditEntityId }) =>
  HttpService.getWithAuth(
    GET_HOST_CREDIT_ENTITY_CREDIT_REQUESTS_API_URL({ hostCreditEntityId })
  );

const getOpsUserListAPI = () =>
  HttpService.getWithAuth(GET_OPS_USER_LIST_API_URL());

const addHostCreditEntityAPI = (dataToSubmit) =>
  HttpService.postWithAuth(ADD_HOST_CREDIT_ENTITY_API_URL(), dataToSubmit);

const getCreditRequestReasonsAPI = () =>
  HttpService.getWithAuth(GET_CREDIT_REQUEST_REASONS_API_URL());

const createHostCreditEntityCreditRequestAPI = ({
  dataToSubmit,
  hostCreditEntityId
}) =>
  HttpService.postWithAuth(
    CREATE_HOST_CREDIT_ENTITY_CREDIT_REQUEST_API_URL({ hostCreditEntityId }),
    dataToSubmit
  );

const updateHostCreditEntityCreditRequestAPI = ({
  hostCreditEntityCreditRequestId,
  payload
}) =>
  HttpService.putWithAuth(
    UPDATE_HOST_CREDIT_ENTITY_CREDIT_REQUEST_API_URL({
      hostCreditEntityCreditRequestId
    }),
    payload
  );

const updateHostCreditEntityAPI = ({
  creditFacilityEnabled,
  hostCreditEntityId
}) =>
  HttpService.putWithAuth(
    UPDATE_HOST_CREDIT_ENTITY_API_URL({
      hostCreditEntityId
    }),
    { creditFacilityEnabled }
  );

export const hostCreditEntityProfileTabs = {
  PROFILE: {
    id: '#host-credit-entity-profile-tab',
    label: 'Profile'
  },
  ACTIVE_CREDIT: {
    icon: {
      alt: 'host credit entity active credit',
      name: 'notification.svg',
      style: { height: 24, width: 24 }
    },
    id: '#host-credit-entity-active-credit-tab',
    label: 'Active Credit'
  },
  CREDIT_HISTORY: {
    id: '#host-credit-entity-credit-history-tab',
    label: 'Credit History'
  }
};

export const hostCreditEntityProfileStepFieldErrorConfig = {
  [hostCreditEntityProfileTabs.PROFILE.id]: [],
  [hostCreditEntityProfileTabs.ACTIVE_CREDIT.id]: [],
  [hostCreditEntityProfileTabs.CREDIT_HISTORY.id]: []
};

export const isPlannerCreditFacilityReviewer =
  PLANNER_CREDIT_FACILITY_REVIEWERS.length === 0 ||
  PLANNER_CREDIT_FACILITY_REVIEWERS.includes(getPlannerId());

export const fetchOpsUserList = async ({
  setOpsUserList,
  setShowToast = () => {}
}) => {
  const { entity, message, status } = await getOpsUserListAPI();
  const success = status && !isEmptyOrNull(entity?.rows);
  if (success) {
    const { rows } = entity;
    setOpsUserList(rows);
  } else {
    setOpsUserList([]);
    setShowToast({
      message,
      show: true,
      successToast: false
    });
  }
  return { message, success };
};

const getHostCreditEntityCreditRequests = async ({
  hostCreditEntityId,
  setHostCreditEntityCreditRequests
}) => {
  const { entity, message, status } =
    await getHostCreditEntityCreditRequestsAPI({
      hostCreditEntityId
    });
  if (status) {
    setHostCreditEntityCreditRequests(entity);
  }
  return { message, success: Boolean(status) };
};

const getHostCreditEntityHostList = async ({
  hostCreditEntityId,
  setHostCreditEntityHostList
}) => {
  const { entity, message, status } = await getHostCreditEntityHostListAPI({
    hostCreditEntityId
  });
  const success = status && !isEmptyOrNull(entity);
  if (success) {
    setHostCreditEntityHostList(entity);
  }
  return { message, success };
};

export const fetchHostCreditEntityHostListOnPageLoad = async ({
  hostCreditEntityId,
  setHostCreditEntityCreditRequests,
  setHostCreditEntityHostList,
  setLoading,
  setShowToast
}) => {
  const toast = { message: null, show: true, successToast: true };
  setLoading(true);
  try {
    const [
      { message: hostListApiMessage, success: successHostListAPI },
      { message: creditRequestsApiMessage, success: successCreditRequestsAPI }
    ] = await Promise.all([
      getHostCreditEntityHostList({
        hostCreditEntityId,
        setHostCreditEntityHostList
      }),
      getHostCreditEntityCreditRequests({
        hostCreditEntityId,
        setHostCreditEntityCreditRequests
      })
    ]);

    switch (true) {
      case !successHostListAPI:
        toast.message = hostListApiMessage;
        break;
      case !successCreditRequestsAPI:
        toast.message = creditRequestsApiMessage;
        break;
      default:
        toast.message = null;
        break;
    }

    toast.successToast = successCreditRequestsAPI && successCreditRequestsAPI;
    toast.show = !toast.successToast;
  } catch (error) {
    toast.message = error.message || 'Failed to Fetch API';
    toast.successToast = false;
  } finally {
    setShowToast(toast);
    setLoading(false);
  }
};

const getHostCreditEntityProfileDetails = async ({
  hostCreditEntityId,
  setHostCreditEntityProfileDetails
}) => {
  const { entity, message, status } =
    await getHostCreditEntityProfileDetailsAPI({
      hostCreditEntityId
    });
  const success = status && !isEmptyOrNull(entity);
  if (success) {
    setHostCreditEntityProfileDetails(entity);
  }
  return { message, success };
};

export const fetchHostCreditEntityForBookOrder = async ({
  hostCreditEntityId,
  setHostCreditEntityInformation,
  setShowToast
}) => {
  const { message, success } = await getHostCreditEntityProfileDetails({
    hostCreditEntityId,
    setHostCreditEntityProfileDetails: setHostCreditEntityInformation
  });
  !success &&
    setShowToast({
      message,
      show: true,
      successToast: success
    });
};

export const fetchHostCreditEntityProfilePageDetails = async ({
  hostCreditEntityId,
  setHostCreditEntityCreditRequests,
  setHostCreditEntityProfileDetails,
  setLoading,
  setOpsUserList,
  setShowToast
}) => {
  const toast = { message: null, show: false, successToast: true };
  setLoading(true);
  try {
    const [
      {
        message: hostCreditEntityProfileApiMessage,
        success: successHostCreditEntityProfileAPI
      },
      { message: creditRequestsApiMessage, success: successCreditRequestsAPI },
      { message: opsUserListApiMessage, success: successOpsUserListAPI }
    ] = await Promise.all([
      getHostCreditEntityProfileDetails({
        hostCreditEntityId,
        setHostCreditEntityProfileDetails
      }),
      getHostCreditEntityCreditRequests({
        hostCreditEntityId,
        setHostCreditEntityCreditRequests
      }),
      fetchOpsUserList({ setOpsUserList })
    ]);

    switch (true) {
      case !successHostCreditEntityProfileAPI:
        toast.message = hostCreditEntityProfileApiMessage;
        break;
      case !successCreditRequestsAPI:
        toast.message = creditRequestsApiMessage;
        break;
      case !successOpsUserListAPI:
        toast.message = opsUserListApiMessage;
        break;
      default:
        toast.message = null;
        break;
    }

    toast.successToast =
      successHostCreditEntityProfileAPI &&
      successCreditRequestsAPI &&
      successOpsUserListAPI;
    toast.show = !toast.successToast;
  } catch (error) {
    toast.message = error.message || 'Failed to Fetch API';
    toast.successToast = false;
  } finally {
    setShowToast(toast);
    setLoading(false);
  }
};

const fetchHostCreditEntityCreditRequest = async ({
  hostCreditEntityId,
  setHostCreditEntityCreditRequests
}) => {
  try {
    const { entity, message, status } =
      await getHostCreditEntityCreditRequestsAPI({
        hostCreditEntityId
      });
    if (status) {
      setHostCreditEntityCreditRequests(entity);
    }
    return { message, success: Boolean(status) };
  } catch (error) {
    return {
      message: error.message || 'Failed to Fetch API',
      success: false
    };
  }
};

export const getHostCreditEntityCreditRequest = async ({
  hostCreditEntityId,
  setHostCreditEntityCreditRequests,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { message, success } = await fetchHostCreditEntityCreditRequest({
    hostCreditEntityId,
    setHostCreditEntityCreditRequests
  });
  setShowToast({
    message,
    show: !success,
    successToast: false
  });
  setLoading(false);
};

export const updateHostCreditEntityCreditRequest = async ({
  hostCreditEntityId,
  id,
  payload,
  setHostCreditEntityCreditRequests,
  setHostCreditEntityProfileDetails,
  setIsEditable,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { message, status } = await updateHostCreditEntityCreditRequestAPI({
    hostCreditEntityCreditRequestId: id,
    payload
  });
  if (status) {
    await Promise.all([
      fetchHostCreditEntityCreditRequest({
        hostCreditEntityId,
        setHostCreditEntityCreditRequests
      }),
      getHostCreditEntityProfileDetails({
        hostCreditEntityId,
        setHostCreditEntityProfileDetails
      })
    ]);
  }
  setShowToast({
    message,
    show: true,
    successToast: status
  });
  setLoading(false);
  setIsEditable(false);
};

export const updateHostCreditEntity = async ({
  creditFacilityEnabled,
  hostCreditEntityId,
  setHostCreditEntityProfileDetails,
  setIsEditable,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { message, status } = await updateHostCreditEntityAPI({
    creditFacilityEnabled,
    hostCreditEntityId
  });
  if (status) {
    await getHostCreditEntityProfileDetails({
      hostCreditEntityId,
      setHostCreditEntityProfileDetails
    });
  }
  setShowToast({
    message,
    show: true,
    successToast: status
  });
  setLoading(false);
  setIsEditable(false);
};

export const createHostCreditEntity = async ({
  dataToSubmit,
  setError,
  setSelectedHostCreditEntityName,
  setShowToastContainer,
  setValueOnParent
}) => {
  const { entity, status, message } =
    await addHostCreditEntityAPI(dataToSubmit);
  if (status) {
    const { isExists } = entity;
    if (isExists) {
      setError('name', {
        message: 'Entity Name already exists.',
        type: 'custom'
      });
    } else {
      setSelectedHostCreditEntityName(entity.hostCreditEntity.name);
      setValueOnParent('hostCreditEntityId', entity.hostCreditEntity.id);
      setShowToastContainer({
        message,
        show: true,
        successToast: status
      });
    }
  } else {
    setShowToastContainer({
      message,
      show: true,
      successToast: status
    });
  }
};

export const fetchCreditRequestReasons = async ({
  setCreditRequestReasons,
  setShowToast
}) => {
  const { entity, message, status } = await getCreditRequestReasonsAPI();
  if (status && !isEmptyOrNull(entity)) {
    setCreditRequestReasons(entity);
  } else {
    setCreditRequestReasons([]);
    setShowToast({
      message,
      show: true,
      successToast: status
    });
  }
};

export const createHostCreditEntityCreditRequest = async ({
  dataToSubmit,
  hostCreditEntityId,
  setHostCreditEntityCreditRequests,
  setShowToastContainer
}) => {
  const { status } = await createHostCreditEntityCreditRequestAPI({
    dataToSubmit,
    hostCreditEntityId
  });
  if (status) {
    await fetchHostCreditEntityCreditRequest({
      hostCreditEntityId,
      setHostCreditEntityCreditRequests
    });
  }

  setShowToastContainer({
    message: status
      ? 'Credit applied successfully!'
      : 'Something went wrong! Failed to apply',
    show: true,
    successToast: status
  });
};

export const getCreditEntityStatisticsParamaters = ({
  eventStatistics: {
    averageEventValue,
    bookedEventCount,
    grossEventValue,
    inquiredEventCount
  }
} = {}) => ({
  averageEventValue: {
    alt: 'event-gev-icon',
    icon: 'gev-fill-icon.svg',
    label: 'AEV',
    value: convertToCurrencyFormat({
      roundOff: true,
      value: parseFormatPriceNumberValueFromAPI(averageEventValue),
      withCurrencyPrefix: false
    })
  },
  bookedEventCount: {
    alt: 'book-event-icon',
    icon: 'book-event-fill-icon.svg',
    label: 'Booked Events',
    value: bookedEventCount
  },
  grossEventValue: convertToCurrencyFormat({
    roundOff: true,
    value: parseFormatPriceNumberValueFromAPI(grossEventValue)
  }),
  inquiredEventCount: {
    label: 'Events Inquired',
    value: inquiredEventCount
  },
  marginPercent: {
    alt: 'margin-icon',
    icon: 'margin-icon.svg',
    label: 'Lifetime Margin %',
    value: 'NA'
  }
});

export const validateApprovedCreditLimit = ({
  approvedCreditLimit,
  selectedReviewActionForCreditRequest
}) => {
  if (
    selectedReviewActionForCreditRequest ===
    REVIEW_CREDIT_REQUEST_CTA_PAIR.APPROVE.value
  ) {
    return !isEmptyOrNull(approvedCreditLimit) && approvedCreditLimit > 0;
  }
  return true;
};

export const validateReviewerNotes = ({
  reviewerNotes,
  selectedReviewActionForCreditRequest
}) => {
  if (
    selectedReviewActionForCreditRequest ===
    REVIEW_CREDIT_REQUEST_CTA_PAIR.REJECT.value
  ) {
    return !isEmptyOrNull(reviewerNotes);
  }
  return true;
};
